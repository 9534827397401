const HomeViewStyles = () => ({
  main: {
    marginTop: '200px',
  },
  dashboardWrapper: {
    position: "absolute",
    top: "64px",
    left: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "calc(100% - 64px)",
    minHeight: '650px',
    backgroundColor: "#ffffff",
    // paddingBottom: "24px",
    flexWrap: "nowrap",
  },
  imgWrapper:{
    padding: '0 10px',
    '&>svg': {
      maxWidth: '100%',
      maxHeight: '100%',
      display: 'block',
    },
  },
  title: {
    fontFamily: "Avenir Next Rounded Medium",
    color: '#4a4e52',
    fontSize: '2.325rem',
  },
  description: {
    fontFamily: "Avenir Next Rounded Medium",
    color: '#4a4e52',
    fontSize: '1.125rem',
    fontWeight: '200',
  },
});

export default HomeViewStyles;
